import React, { useState, useEffect } from 'react';
import { useStore } from 'context/store-context';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Text } from 'components/Text';
import { DataTable } from 'components/DataTable';
import { SearchBox } from 'components/Search';
import { Pagination } from 'components/Pagination';
import { DataFilter } from 'components/DataFilter';
import { DatePicker } from 'components/DatePicker';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { LoadingScreen } from 'components/LoadingScreen';
import { billings, columns, billing } from './data/billing-data';
import { format, startOfDay } from 'date-fns';
import {
  NotificationTypes,
  apiErrorHandler,
  getCurrentMonth,
  getDateXDaysAgo,
  getCurrentTierInfo,
  getStoreCurrencyFormatter,
} from 'utils';
import { withNotification } from 'components/Notification';
import {
  FILTER_BILLINGS,
  GET_BILLINGS_DATA,
  GET_PROTECTED_ORDERS_COUNT,
  GET_DASHBOARD_ANALYTICS,
  GET_BILLING_INVOICE_LINK,
} from 'gql/queries';
import { CANCEL_STORE_PLAN } from 'gql/mutations';
import { Order } from 'types/order';

export const Billings = withNotification(({ showNotification }: any) => {
  const [allBillings, setAllBillings] = useState([]);
  const [filterInput, setFilterInput] = useState<any>({});
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(),
    new Date(),
  ]);
  const [protectedOrders, setProtectedOrders] = useState([]);
  const [aggregatedFee, setAggregatedFee] = useState<any>({});
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { storeId, storeProperties } = useStore();

  const [getBillingsData, { data, loading }] = useLazyQuery(GET_BILLINGS_DATA, {
    onCompleted(data) {
      setAllBillings(data?.bills);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [
    cancelStorePlan,
    { error: cancelPlanError, loading: cancelPlanLoading },
  ] = useMutation(CANCEL_STORE_PLAN, {
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [filterBillings, { loading: filterLoading }] = useLazyQuery(
    FILTER_BILLINGS,
    {
      onCompleted: (data) => {
        setAllBillings(data?.bills);
      },
      onError(error) {
        const newError = apiErrorHandler(error);
        showNotification(NotificationTypes.error, newError?.message);
      },
    },
  );

  const [getInvoiceLink] = useLazyQuery(GET_BILLING_INVOICE_LINK, {
    onCompleted: (data) => {
      if (data?.link && data.link.includes('http')) {
        window.open(data.link);
      } else {
        showNotification(
          NotificationTypes.pending,
          'Invoice PDF is not ready yet, please try again later',
        );
      }
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  const [getProtectedOrdersCount, { data: countData }] = useLazyQuery(
    GET_PROTECTED_ORDERS_COUNT,
  );

  const [getDashboardAnalytics] = useLazyQuery(GET_DASHBOARD_ANALYTICS, {
    onCompleted: (data) => {
      setProtectedOrders(data.protectedOrders);
      setAggregatedFee(data.protectionFees);
    },
    onError(error) {
      const newError = apiErrorHandler(error);
      showNotification(NotificationTypes.error, newError?.message);
    },
  });

  useEffect(() => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    const firstDayOfMonth = new Date(y, m, 1);

    const dateTomorrow = new Date(
      new Date(date).setDate(new Date(date).getDate() + 1),
    );
    storeId &&
      getBillingsData({
        variables: {
          storeId,
        },
      });
    storeId &&
      getDashboardAnalytics({
        variables: {
          storeId,
          startDate: format(
            startOfDay(firstDayOfMonth ?? getDateXDaysAgo(30)),
            'yyyy-MM-dd',
          ),
          endDate: format(
            startOfDay(dateTomorrow ?? getDateXDaysAgo(0)),
            'yyyy-MM-dd',
          ),
        },
      });
    storeId &&
      getProtectedOrdersCount({
        variables: {
          filters: {
            storeId: { _eq: storeId },
            protectionTotal: { _is_null: false },
            _and: [
              {
                createdAt: {
                  _gte: format(
                    startOfDay(firstDayOfMonth ?? getDateXDaysAgo(30)),
                    'yyyy-MM-dd',
                  ),
                },
              },
              {
                createdAt: {
                  _lte: format(
                    startOfDay(dateTomorrow ?? getDateXDaysAgo(30)),
                    'yyyy-MM-dd',
                  ),
                },
              },
            ],
          },
        },
      });
  }, [
    getBillingsData,
    getDashboardAnalytics,
    getProtectedOrdersCount,
    storeId,
  ]);

  useEffect(() => {
    if (Object.keys(filterInput).length > 0)
      filterBillings({
        variables: {
          filters: { ...filterInput, storeId: { _eq: storeId } },
          order_by: [
            {
              createdAt: 'desc',
            },
          ],
        },
      }).then((data) => {
        setAllBillings(data?.data?.bills);
      });
  }, [filterInput, filterBillings, storeId]);

  const search = () => {
    setFilterInput({
      ...filterInput,
      _or: [{ number: { _eq: Number(searchValue) } }],
    });
  };

  const handleSearch = (value: string) => {
    if (value === '') {
      const obj = { ...filterInput };
      delete obj._or;
      setSearchValue(value);
      /**
       * this was done this way to ensure that whenever the search is cleared, 
       it also checks that there are no filter rules there to ensure that it sets the 
       table back to default
       */
      Object.keys(obj).length === 0 && setAllBillings(data?.bills);
      return setFilterInput(obj);
    }
    setSearchValue(value);
  };

  const onFilter = (option: string, category: string) => {
    if (option === '') {
      const obj = { ...filterInput };
      delete obj[category];
      setFilterInput(obj);
      return searchValue === '' && setAllBillings(data?.bills);
    }
    if (category === 'paid') {
      return setFilterInput({
        ...filterInput,
        paid: { _eq: option === 'Paid' ? true : false },
      });
    }
    if (category === 'billType') {
      return setFilterInput({
        ...filterInput,
        billType: {
          _eq: option.split(' ').join('_').toUpperCase(),
        },
      });
    }
    return setFilterInput({
      ...filterInput,
      [category]: { _eq: option.split(' ').join('_').toUpperCase() },
    });
  };

  const handleSelectedRow = (item: billing) => {
    if (selectedRows.includes(item.id))
      return setSelectedRows(
        [...selectedRows].filter((row) => row !== item.id),
      );
    return setSelectedRows([...selectedRows, item.id]);
  };

  const handleSelectAllRows = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    const rows = billings.map((order: billing) => order.id);
    if (target.checked) return setSelectedRows(rows);
    return setSelectedRows([]);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const handleCancelPlan = () => {
    cancelStorePlan({
      variables: { input: { storeId } },
    })
      .then(() => {
        setShowConfirmationModal(!showConfirmationModal);
        return showNotification(
          NotificationTypes.success,
          'Store plan cancelled successfully',
        );
      })
      .catch((err) => {
        setShowConfirmationModal(!showConfirmationModal);
        return showNotification(
          NotificationTypes.error,
          cancelPlanError ? cancelPlanError.message : 'Could not cancel plan',
        );
      });
  };

  const handleSetDate = (date: any) => {
    setDateRange(date);
    const obj = { ...filterInput };
    if (!date[0] && !date[1]) {
      delete obj._and;
      setFilterInput(obj);
      return searchValue === '' && setAllBillings(data.bills);
    }

    if (date[1])
      setFilterInput({
        ...filterInput,
        _and: [
          { createdAt: { _gte: format(new Date(date[0]), 'yyyy-MM-dd') } },
          { createdAt: { _lte: format(new Date(date[1]), 'yyyy-MM-dd') } },
        ],
      });
  };
  const accruedCost = aggregatedFee?.aggregate?.sum?.amount
    ? aggregatedFee?.aggregate?.sum?.amount
    : '0.00';

  const protectedRevenue = protectedOrders
    .reduce((total: number, order: Order) => {
      return total + order.protectionTotal;
    }, 0)
    .toFixed(2);

  const protectedOrdersCount = countData?.ordersAggregate?.aggregate.count;

  const onShowDetails = (item: any, type: string) => {
    getInvoiceLink({
      variables: {
        storeId,
        invoiceId: item.invoiceId,
        invoicePdfId: item.invoicePdfId,
        type,
      },
    });
  };

  if (loading) return <LoadingScreen />;

  return (
    <div>
      <ConfirmationModal
        showModal={showConfirmationModal}
        setShowModal={(value) => setShowConfirmationModal(value)}
        title={`Are you sure you want to cancel your plan?`}
        handleConfirm={handleCancelPlan}
        subMessage="The plan will end at the the end of the billing cycle"
        btnText="Cancel plan"
        mainBtnClassname="text-white"
        type={NotificationTypes.error}
        isLoading={cancelPlanLoading}
      />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <Text value="Billing" type="h1" />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 mt-3">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10 xs:grid-cols-1 sm:grid-cols-3">
          <div className="p-[30px] border border-gray-300 mt-2 rounded-[20px] border-l-8 border-l-gray-100">
            <div className="">
              <div className="flex justify-between">
                <Text
                  value="Guarantee Revenue"
                  type="body"
                  className="text-gray-600 text-2xl"
                />
              </div>
              <Text
                value={`So far in ${getCurrentMonth()}`}
                type="body"
                className="text-gray-500 mt-2"
              />
              <Text
                value={`$${protectedRevenue}`}
                type="h2"
                className="text-blue-500 mt-2"
              />
            </div>
          </div>
          <div className="p-[30px] border border-gray-300 mt-2 rounded-[20px] border-l-8 border-l-gray-100">
            <div className="">
              <div className="flex justify-between">
                <Text
                  value="Accrued Cost"
                  type="body"
                  className="text-gray-600 text-2xl"
                />
              </div>
              <Text
                value={`So far in ${getCurrentMonth()}`}
                type="body"
                className="text-gray-500 mt-2"
              />
              <Text
                value={`${getStoreCurrencyFormatter(
                  storeProperties?.currency,
                  accruedCost,
                )}`}
                type="h2"
                className="text-blue-500 mt-2"
              />
            </div>
          </div>

          <div className="p-[30px] border border-gray-300 mt-2 rounded-[20px] style-bg">
            <div className="">
              <div className="flex justify-between">
                <Text
                  value={`Guaranteed Orders`}
                  type="body"
                  className="text-white text-2xl"
                />
              </div>
              <Text
                value={`So far in ${getCurrentMonth()}`}
                type="body"
                className="text-white mt-2"
              />
              <Text
                value={`${Number(protectedOrdersCount)}`}
                type="h2"
                className="text-white mt-2"
              />
              <div className="flex">
                <Text
                  value={`Billing: Tier ${
                    getCurrentTierInfo(protectedOrdersCount).level
                  }`}
                  type="body"
                  className="text-white"
                />
                <a
                  className="align-text-bottom text-white ml-2"
                  href="https://www.shipaid.com/pricing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Text value="(details)" type="body" />
                </a>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:flex justify-between mb-2 mt-12 sm:block">
          <div className="mb-4 sm:mb-4 md:mb-2 flex items-center">
            <div className="w-80">
              <SearchBox
                search={search}
                searchValue={searchValue}
                onChange={(searchValue) => handleSearch(searchValue)}
                placeholder="Search by invoice no"
              />
            </div>
            {filterLoading && (
              <div
                style={{
                  borderColor: 'rgba(37,99,235, 0.2)',
                  borderTopColor: '#2564eb',
                }}
                className="inline-block border-2 rounded-full animate-spin w-3 h-3 ml-2"
              />
            )}
          </div>
          <div className="display-block sm:flex">
            <DataFilter
              filterBy="Status"
              options={['Paid', 'Unpaid']}
              setSelectedOption={(option) => onFilter(option, 'paid')}
              selectedOption={filterInput.paid ? filterInput.paid : ''}
              wrapperClass="mr-4"
            />
            <DataFilter
              filterBy="Type"
              options={['Fees', 'Subscription']}
              setSelectedOption={(option) => onFilter(option, 'billType')}
              selectedOption={filterInput.billType ? filterInput.billType : ''}
              wrapperClass="mr-4"
            />
            <DatePicker
              className="mt-2 sm:mt-0"
              selectsRange
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              setValue={handleSetDate}
              monthsShown={2}
              placeHolder="Select range"
              isClearable
            />
          </div>
        </div>
        <div className="">
          <DataTable
            data={(allBillings || []).map((item: any) => ({
              ...item,
              onShowDetails: onShowDetails.bind(null, item),
            }))}
            columns={columns}
            handleSelectedRow={handleSelectedRow}
            onSelectAll={handleSelectAllRows}
            allChecked={selectedRows}
            emptyMessage={
              searchValue
                ? `No search results matches "${searchValue}"`
                : 'No data'
            }
          />
        </div>
        <div className="mt-3">
          {allBillings?.length > 10 && (
            <Pagination
              totalPages={1}
              currentPage={page}
              resultsRange={10}
              totalResults={allBillings.length}
              onPageClick={onPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
});
