import React, { useState } from 'react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import ReactDOM from 'react-dom';

interface IOptionMenuProps {
  options: { label: string; onClick: () => void }[];
}

const OptionMenu = ({ options = [] }: IOptionMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const menuPosition = buttonRef.current
    ? {
        top: buttonRef.current.getBoundingClientRect().bottom + window.scrollY,
        left: buttonRef.current.getBoundingClientRect().left + window.scrollX,
      }
    : { top: 0, left: 0 };

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        className="p-2 text-gray-600 hover:text-gray-800 focus:outline-none"
        onClick={toggleMenu}
      >
        <DotsVerticalIcon className="h-6 w-6" />
      </button>

      {isOpen &&
        ReactDOM.createPortal(
          <div
            className="absolute bg-white border border-gray-300 rounded-md shadow-lg z-50"
            style={{
              top: `${menuPosition.top}px`,
              right: '20px',
            }}
          >
            {options.map((option, index) => (
              <button
                key={index}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none"
                onClick={option.onClick}
              >
                {option.label}
              </button>
            ))}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default OptionMenu;
