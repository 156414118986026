import React, { useEffect, useState } from 'react';
import { Text } from '../Text';
import ShopifyConnection from './ShopifyConnection';
import StripeSetup from './StripeSetup';
import { useMutation } from '@apollo/client';
import {
  SEND_CUSTOM_APP_REQUEST,
  SETUP_STRIPE_CONNECTION,
} from 'gql/mutations';
import { useStore } from 'context/store-context';
import Success from './Success';
import useCustomApp from 'hooks/useCustomApp';
import { withNotification } from 'components/Notification';
import { NotificationTypes } from 'utils';
// import { Loader } from 'components/Loader';

interface CustomAppOnboardingProps {
  onComplete?: () => void;
}

const CustomAppOnboarding: React.FC<
  CustomAppOnboardingProps & { showNotification: any }
> = ({ showNotification }) => {
  const { storeId } = useStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState('');
  const [isShopifyConnected, setIsShopifyConnected] = useState(false);

  const { hasBilling, hasCustomApp } = useCustomApp();

  useEffect(() => {
    if (hasCustomApp && hasBilling) {
      setCurrentStep(3);
      setIsShopifyConnected(true);
    } else if (
      storeId ? hasBilling && !hasCustomApp : !hasBilling && hasCustomApp
    ) {
      setCurrentStep(2);
      setIsShopifyConnected(storeId ? false : true);
    } else if (storeId ? !hasBilling : !hasCustomApp) {
      setCurrentStep(1);
    }
  }, [hasCustomApp, hasBilling, storeId]);

  const { storeProperties, storeDomain } = useStore();

  const [currentStoreDomain, setCurrentStoreDomain] = useState(storeDomain);

  useEffect(() => {}, [
    storeProperties?.billing,
    storeProperties?.useCustomApp,
  ]);

  const steps = [
    { id: 1, title: 'Connect Stripe' },
    { id: 2, title: 'Install ShipAid' },
    { id: 3, title: 'Complete' },
  ];

  const [
    sendCustomAppCreationRequest,
    { loading: sendCustomAppCreationRequestLoading },
  ] = useMutation(SEND_CUSTOM_APP_REQUEST, {
    onError(error) {
      setError(error?.message || 'Something went wrong, please try again');
    },
    onCompleted() {
      showNotification(
        NotificationTypes.success,
        'Custom App request is sent!',
      );
    },
  });

  const [setupStripeConnection, { loading: setupStripeConnectionLoading }] =
    useMutation(SETUP_STRIPE_CONNECTION, {
      onError: (data) => {
        setError(data.message || 'Something went wrong, please try again');
      },
      onCompleted: (data) => {
        setError('');
        window.location.href = data.setupStripeConnection;
      },
    });

  const handleShopifyCompleteAction = (store: string, installLink: string) => {
    setCurrentStoreDomain(store);
    window.location.href = installLink;
  };

  const handleStripeConnection = (email: string) => {
    setupStripeConnection({
      variables: {
        store: currentStoreDomain,
        email,
      },
    });
  };

  const onAskShipAid = (name: string, email: string, domain: string) => {
    sendCustomAppCreationRequest({
      variables: {
        input: {
          name,
          email,
          storeName: domain,
        },
      },
    });
  };

  const renderStepContent = (step: number) => {
    // if (isLoading) {
    //   return (
    //     <div className="mt-20 w-24 h-24">
    //       <Loader text="Please wait..." />
    //     </div>
    //   );
    // }
    switch (step) {
      case 1:
        return (
          <StripeSetup
            isLoading={setupStripeConnectionLoading}
            onComplete={handleStripeConnection}
          />
        );
      case 2:
        return (
          <ShopifyConnection
            onAskShipAid={onAskShipAid}
            isOnAskShipAidLoading={sendCustomAppCreationRequestLoading}
            isLoading={false}
            isConnected={isShopifyConnected}
            onComplete={handleShopifyCompleteAction}
          />
        );
      case 3:
        return <Success />;
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto p-6">
      <div className="text-center mb-8">
        <Text
          value="Complete These Essential Steps"
          type="h2"
          className="text-3xl font-bold text-blue-700 mb-2"
        />
        <Text
          value="These steps are mandatory to set up your account properly"
          className="text-gray-600"
        />
      </div>
      <div>
        <div className="flex max-w-3xl mx-auto items-center">
          {steps.map((step, index) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col relative items-center">
                <div className="relative">
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${
                      step.id <= currentStep
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 text-gray-500'
                    }`}
                  >
                    {step.id}
                  </div>
                  <div
                    className={`absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-14 h-14 rounded-full ${
                      step.id <= currentStep
                        ? 'border-2 border-blue-500'
                        : 'border-2 border-dashed border-gray-300'
                    }`}
                  ></div>
                </div>
                <Text
                  value={step.title}
                  className={`align-center whitespace-nowrap absolute mt-12 text-sm ${
                    step.id <= currentStep ? 'text-blue-500' : 'text-gray-500'
                  }`}
                />
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`flex-grow h-1 ${
                    step.id < currentStep ? 'bg-blue-500' : 'bg-gray-200'
                  }`}
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex justify-center flex-col items-center bg-white rounded-lg shadow-md pt-10 px-4 pb-4">
        {error && (
          <div className="mb-5 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            <p className="flex items-center">
              <svg
                className="w-5 h-5 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clipRule="evenodd"
                />
              </svg>
              {error}
            </p>
          </div>
        )}
        <div>{renderStepContent(currentStep)}</div>
      </div>
    </div>
  );
};

export default withNotification(CustomAppOnboarding);
